import React, { useState } from "react"
import styled from "@emotion/styled"
import { FaShoppingCart, FaAngleDown, FaAngleUp } from "react-icons/fa"
import { injectIntl } from "gatsby-plugin-intl"

export const SideBarWrapper = styled.div`
  width: 100%;

  @media (min-width: 1600px) {
  }

  @media (max-width: 992px) {
    position: relative;
    max-width: 100%;
  }
`

export const DesktopView = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  padding: 20px 10px 10px 30px;

  @media (max-width: 992px) {
    padding: 20px 20px 10px;
    position: fixed;
    top: 0px;
    z-index: 9;
    margin: 0px;
    left: 0px;
    height: calc(100% - 59px);
    overflow-y: auto;
    display: ${({ show }) => (show ? "block" : "none")};
  }
`

export const Title = styled.h3`
  font-weight: bold;
  color: #f37f50;
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  font-family: "GothamLight";
  font-weight: bold;
  margin: 0px;
`

export const SubTitle = styled.h4`
  line-height: 20px;
  text-transform: uppercase;
  color: #262626;
  font-size: 15px;
  letter-spacing: 0.05em;
  text-align: center;
  font-family: "GothamLight";
  font-weight: 500;
  margin: 0px;
`

export const ProductView = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px 0px;
`

export const ProductImageWrapper = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Image = styled.img`
  width: 100%;
  margin: 0px;
  margin-bottom: 5px;
`

export const ProductButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

export const Button = styled.a`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 0;
  margin: 0;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  padding: 0 1.2em;
  max-width: 100%;
  transition: transform 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s,
    opacity 0.3s, color 0.3s;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  background-color: #ff784b;
  border-color: rgba(0, 0, 0, 0.05);

  &:first-of-type {
    margin-right: 5px;
  }

  &:nth-of-type(2) {
    margin-left: 5px;
  }
  &:hover {
    color: #fff;
  }
`

export const MobilePopupBtn = styled.div`
  padding: 20px;
  background: linear-gradient(to right, #ff4800, #ff6e02) transparent !important;
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 3;
  display: none;

  @media (max-width: 992px) {
    display: block;
  }

  &:hover {
    color: #fff;
  }
`

const ProductSideBar = ({ intl, data }) => {
  const [openPopup, setopenPopup] = useState(false)

  const productUrl = (url, Gender) => {
    let productLink = ""
    let newUrl = ""

    newUrl = [undefined, "", "general"].includes(process.env.GATSBY_ACTIVE_ENV)
      ? ""
      : "/" + process.env.GATSBY_CURRENCY_TYPE

    if (process.env.GATSBY_ACTIVE_ENV === "eur" && intl.locale === "fr") {
      if (url.includes("lynefit")) {
        productLink = newUrl + `/shop/solutions-textiles/tshirt-sport/`
      } else if (url.includes("lynehome")) {
        productLink = newUrl + `/shop/solutions-textiles/gilet-pratique/`
      } else {
        productLink = newUrl + `/shop/solutions-textiles/tshirt-discret/`
      }

      if (Gender === "Male") {
        productLink = productLink + "?Gender=Male"
      } else {
        productLink = productLink + "?Gender=Female"
      }
    } else {
      productLink = newUrl + url + (Gender ? "?Gender=" + Gender : "")
    }

    return productLink
  }
  return (
    <SideBarWrapper>
      <DesktopView show={openPopup}>
        <Title>{data.title}</Title>
        <SubTitle>{data.subtitle}</SubTitle>
        {data.list.map((item, index) => {
          return (
            <ProductView key={index.toString()}>
              <ProductImageWrapper href={productUrl(item.link)}>
                <Image className="lazyload" data-src={item.image}></Image>
              </ProductImageWrapper>
              <ProductButtonWrapper>
                <Button href={productUrl(item.link, "Male")}>
                  <FaShoppingCart style={{ marginRight: 5 }} size={14} />
                  {data.homme_text}
                </Button>
                <Button href={productUrl(item.link, "Female")}>
                  <FaShoppingCart style={{ marginRight: 5 }} size={14} />
                  {data.femme_text}
                </Button>
              </ProductButtonWrapper>
            </ProductView>
          )
        })}
      </DesktopView>
      <MobilePopupBtn onClick={() => setopenPopup(!openPopup)}>
        NOS SOLUTIONS
        {openPopup ? (
          <FaAngleUp style={{ marginLeft: 5 }} size={16} />
        ) : (
          <FaAngleDown style={{ marginLeft: 5 }} size={16} />
        )}
      </MobilePopupBtn>
    </SideBarWrapper>
  )
}

export default injectIntl(ProductSideBar)
